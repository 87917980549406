.footer {
    margin-top: auto;
    background-color: black;
    padding-bottom: 5px;
    bottom: 0;
    width: 100%;
}

#footerTxt {
    padding-top: 16px;
    color: white;
    font-size: 1rem;
}

#footerLink {
    text-decoration: none;
    padding: 0 10px;
}

.priv_link {
    text-decoration: none;
    color: white;
    font-size: 0.7rem;
}

.footer a {
    text-decoration: none;
}

.footer span {
    color: white;
}