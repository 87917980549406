.loading {
  margin-top: 10em;
}

.main-section {
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
  margin-top: 4em;
  height: 40em;
  display: flex;
}

.profile-section-dashboard {
  background-color: #1c7cfc;
  width: 20%;
  height: 91%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.profile-area {
  background-color: #ffffff;
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.3);
}

.user-image-name {
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.user-image {
  width: 7em;
  height: 7em;
  border-radius: 100%;
}

.user-name {
  font-weight: lighter;
  font-size: larger;
}

.user-email {
  font-size: x-small;
}

.premuim-button {
  width: 100%;
  height: 3em;
  background-color: #e8f2ff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
}

.crown-img {
  height: 1.7em;
  width: 1.7em;
  margin-right: 0.5em;
}

.premium-text {
  font-size: medium;
}

.premuim-button:hover {
  background-color: #bbd5f8;
}

.log-out {
  width: 70%;
  height: 2.5em;
  background-color: #1070ed;
  margin-bottom: 2em;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  font-size: small;
  transition: background-color 0.5s ease;
}

.log-out:hover {
  background-color: #1070ed94;
}

.right-section {
  width: 80%;
  margin-left: 20%;
}

.nav-tests-remaining-section {
  width: 80%;
  height: 5%;
  display: flex;
  justify-content: center;
}

.nav-tests-remaining {
  height: 100%;
  width: 17em;
  background-color: #1c7cfc;
  font-size: small;
  font-weight: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.nav-tests-remaining1 {
  height: 100%;
  width: 17em;
  background-color: #1c7cfc;
  font-size: small;
  font-weight: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  display: none;
}

.recommended-tests-section {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(255, 255, 255); */
}

.recommended-tests {
  background-color: #e8f2ff;
  height: 65%;
  width: 95%;
  display: flex;
  justify-content: flex-end;
}

.recommended-tests-text {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.recommended-tests-list {
  display: flex;
  /* overflow-x: scroll; */
  overflow-x: auto;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.recommended-tests-list::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  scrollbar-width: auto;
}

.recommended-tests-list::-webkit-scrollbar-track {
  background: #f1f1f100; /* Color of the scrollbar track */
}

.recommended-tests-list::-webkit-scrollbar-thumb {
  background: #c0d7f4; /* Color of the scrollbar thumb */

  border-radius: 2em;
}

.recommended-tests-list::-webkit-scrollbar-thumb:hover {
  background: #5891da; /* Color of the scrollbar thumb on hover */
}

.test {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff; /* Background color */
  border-radius: 0.1em;
  height: 8em;
  width: 9.6em;
  box-sizing: border-box;
  /* border: 4px solid #1c7cfc; */
}

.testBorder:hover {
  transform: scale(1.04); /* Increase the size by 20% on hover */
}

.testBorder {
  /* border: 3.5px solid #ababab; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.178);
  margin-left: 2em;
}

.remaining-tests-images {
  width: 4em;
  padding: 1em;
}

.recommended-tests-list > :first-child {
  margin-left: 1em;
}

.test-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0048a8;
  background-color: #e8f2ff;
  width: 100%;
  box-sizing: content-box;
  height: 2em;
  padding-top: 0.2em;
  font-size: small;
}

.arrow-section {
  position: absolute;
  background-color: #bbd7f8d4;
  background-repeat: no-repeat;
  /* background-size: 3.2em; */
  height: 11.7em;
  width: 5%;
  transition: background-color 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.arrow-section:hover {
  background-color: #cbe1fad4;
}

.arrow-section.clicked {
  background-color: #86bbf7d4;
}

.arrow-img {
  height: 6em;
  width: 1.8em;
}

.test-history-section {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.1em;
  padding-bottom: 4em;

  /* background-color: rgb(255, 255, 255); */
}

.not-taken-test-text {
  font-weight: bold;
  width: 100%;
}

.history-test-text-section-new {
  width: 100%;
}

.history-image-text-section-new {
  width: 100%;
}

.test-history-list {
  /* background-color: #e8f2ff; */

  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.test-history-list > :first-child {
  margin-top: 0em;
}

.test-history-card {
  background-color: #e8f2ff;
  height: 10em;
  width: 100%;
  margin-top: 2em;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}

/* .test-history-card:hover {
  background-color: #d2e5fe;
  /* transform: scale(1.02); } */

.history-image-text-section {
  width: 50%;
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  text-align: start;
}

.history-test-img {
  width: 6.2em;
  margin-left: 3em;
}

.history-test-text-section {
  margin-left: 3em;
}

.history-buttons-section {
  width: 50%;
  height: 100%;
  /* background-color: rgb(255, 0, 0); */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.history-buttons-test {
  background-color: white;
  height: 7em;
  width: 8.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.178);
}

.history-buttons-test:hover {
  transform: scale(1.03);
}

.history-buttons-img-sec {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.retake-test-img {
  width: 3em;
}

.restart-icon {
  font-size: 3em; /* Adjust the font size to scale up */
  color: #1c7cfc;
}

.history-test-text {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  font-family: Arial, sans-serif;
  animation: loading-dots 4s infinite;
}

@keyframes loading-dots {
  0% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
}

@media (max-width: 800px) {
  .main-section {
    flex-direction: column;
  }

  .profile-section-dashboard {
    background: linear-gradient(to bottom, #c9defa, #ffffff);
    /* background-color: #ffffff; */
    position: relative;
    width: 100%;
    height: 30em;
    display: flex;
    flex-direction: column;
  }

  .profile-area {
    background-color: #ffffff00;
    width: 100%;
    height: 80%;
    margin-top: 5em;
    box-shadow: 0 0 0px #bbd5f8;
  }

  .right-section {
    margin-left: 0px;
    width: 100%;
  }

  .user-image-name {
    background-color: #e8f2ff00;
  }

  .user-image {
    width: 7em;
    height: 7em;
    border-radius: 100%;
  }

  .user-name {
    font-weight: lighter;
    font-size: 2em;
  }

  .user-email {
    font-size: small;
  }

  .premuim-button {
    background-color: #dbebff00;
    width: 10em;
    border-radius: 10px;
    margin-top: 1em;
    height: 2.7em;
    border: none; /* Remove the default border */
    box-shadow: inset 0 0 0 2px #93bff8;
  }

  .premium-text {
    font-size: small;
  }

  .log-out {
    width: 12em;
    height: 3em;
    margin-top: -2em;
  }

  .recommended-tests-section {
    height: 41%;
  }

  .recommended-tests {
    height: 7.5em;
  }

  .arrow-section {
    background-color: #bbd7f8d4;
    height: 7.4em;
  }

  .arrow-section.active {
    background-color: #1364cd96;
  }

  .arrow-img {
    height: 3em;
    width: 1.3em;
  }

  .nav-tests-remaining-section {
    width: 100%;
    display: none;
  }

  .nav-tests-remaining {
    top: -31em;
    display: none;
  }

  .nav-tests-remaining1 {
    position: relative;
    height: 2.5em;
    font-size: smaller;
    display: flex;
    /* top: -31em;
    display: none; */
  }

  .test-history-card {
    height: 5.5em;
    width: 100%;
  }

  .remaining-tests-images {
    width: 2.5em;
    padding: 0px;
    margin-top: 0.4em;
  }

  .test {
    height: 5em;
    width: 5em;
  }

  .test-text {
    font-size: xx-small;
  }

  .history-test-text-section {
    margin-left: 3em;
    font-size: small;
  }

  .history-test-img {
    width: 3em;
    margin-left: 1em;
  }

  .history-buttons-test {
    background-color: white;
    height: 3.5em;
    width: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.178);
  }

  .not-taken-test-text {
    font-size: small;
  }

  .retake-test-img {
    width: 1.7em;
  }

  .restart-icon {
    font-size: 1.7em; /* Adjust the font size to scale up */
    color: #1c7cfc;
  }

  .history-test-text {
    font-size: xx-small;
  }

  .history-test-date {
    font-size: xx-small;
  }
}

@media (max-width: 400px) {
  .history-buttons-section {
    height: 0.5em;
  }

  .test-history-card {
    height: 5.5em;
    width: 100%;
  }

  .history-test-text-section {
    margin-left: 2em;
    font-size: small;
  }

  .history-test-img {
    width: 2em;
    margin-left: 1em;
  }

  .history-buttons-test {
    background-color: white;
    height: 2.5em;
    width: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.178);
  }

  .retake-test-img {
    width: 1em;
  }

  .restart-icon {
    font-size: 1em; /* Adjust the font size to scale up */
    color: #1c7cfc;
  }

  .history-test-text {
    font-size: 6px;
  }

  .history-test-header {
    font-size: 10px;
  }

  .history-test-date {
    font-size: 7px;
  }
}
