.appNav .nav-link {
    color: black; 
    transition: color 0.3s ease;
}

.appNav .nav-link:hover,
.appNav .nav-link:focus,
.appNav .nav-link.active {
    color: rgb(215, 1, 215);
}

.questionBox {
    display: flex;
}

.navbar-toggler {
    background-color: white;
    border-radius: 5px;
}

.navbar-expand-lg {
    background-color: white;
}

.firstContainer {
    display: flex;
    min-height: 65vh;
    margin-top: 10vh;
    margin-bottom: 1.5vh;
    background-color: white;
    color: black;
}

.navbar {
    margin-bottom: 3rem;
    background-color: white;
    max-height: 3.5rem;
    /* box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 1); */
}

nav {
    width: 100%;
    position: fixed;
}

/* seperating the brachselector name and the nav items in the nav bar */
.nav-item-container {
    margin: 0px 2rem 0px 0px;
}

#navbar-brand {
    color: #1C7CFC;
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: bold;
}

.nav-link {
    color: rgb(50, 50, 50);
    font-size: 1.2rem;
}

#intro {
    color: black;
    text-align: left;
    font-weight: bold;
    margin-top: 5vh;
}

#grade {
    color: gray;
    text-align: left;
    font-weight: bold;
}

#desc {
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-size: 1.5rem;
    text-align: left;
    font-weight: lighter;
}

#subContainerOne {
    display:flex;
    flex-direction: column;
    width: 50%;
    padding-left: 30px;
}

#subContainerTwo {
    width: 50%;
}

#mainImg {
    padding-right: 30px;
}

#takeQuizBtn {
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    padding: 7px 15px;
    font-weight: 500;
    font-size: 1.2rem;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    display: flex;
}

#takeTestsLink {
    text-decoration: none;
}

#takeQuizBtn:hover {
    transform: scale(1.04);
    cursor: pointer;
}

#bookAppointmentBtn {
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 0 10px;
}

@media only screen and (max-width: 768px) {
    #mainImg{
        padding-right: 0px;
       width: 100%;
    }

}

@media only screen and (max-width: 480px) {
    .firstContainer{
        min-height: 55vh;
        flex-direction: column;
    }
    #subContainerOne{
        padding-right: 30px;
        width: 80%;
    }
    #subContainerTwo{
      display: none; 
    }

}