@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  margin: 0;
  padding: 0;
}
.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  background-color: white;
  min-height: 100vh;
}

.container {
  width: 80vw;
  margin-top: 90px;
  margin-bottom: 20px;
  padding: 10px 80px;
  background-color: white;
  border-radius: 12px;
  align-self: center;
  justify-self: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease, transform 0.3s ease;
  border-radius: 12px;
  box-shadow: 5px 5px 50px rgb(202, 202, 202);

}

.main-container{
  display: flex;
  flex-direction: column;
}


.container:hover {
  transform: scale(1.01);
}

.card-container {
  margin: 0 15% 0 15%;
}

.main-heading {
  font-size: 35px;
  margin-bottom: 10px;
  color: #1C7CFC;
}

.trait {
  margin-bottom: 60px;
}

.trait-heading {
  font-size: 24px;
  font-weight: bold;
  color: black;
}

.trait-content {
  text-align: justify;
  font-size: 16px;
  color: black;
}

.trait-keywords {
  font-style: italic;
  color: gray;
  text-align: left;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .trait-heading {
    font-size: 20px;
  }

  .trait-content {
    font-size: 14px;
  }

  .trait-keywords {
    font-size: 12px;
  }
}

/* For smaller devices like mobile phones */
@media only screen and (max-width: 576px) {
  .main-heading {
    font-size: 25px;
  }

  .card-container {
    margin: 30px;
  }

  .trait-heading {
    font-size: 18px;
  }

  .trait-content {
    font-size: 12px;
  }

  .trait-keywords {
    font-size: 10px;
  }
}

/* E-Book Page 
.bookContainer {
  padding-bottom: 5%;
}

.downloadTxt {
  color: white;
  font-size: 2rem;
}

.downloadBtn {
  margin-top: 10px;
  background-color: #FFC436;
  color: black;
  border-radius: 12px;
  padding: 6px 15px;
  font-weight: 500;
  margin-left: 10px;
  font-size: 1.1rem;
  border: 1px solid #ddd;
}

.downloadBtn:hover {
  transform: scale(1.08);
} */


.priv_headings {
  color: #1C7CFC;
}

.privMainHeading {
  color: #1C7CFC;
  margin-top: 60px;
}

.privTxt {
  color: black;
}

@media screen and (min-width: 1000px) {
  .dropdown-tests {
    position: absolute;
    list-style: none;
    /* padding: 10px; */
    /* margin: 10px; */
    border-radius: 10px;
    border: 2px solid white;
    /* color: red; */
    background-color: rgb(25, 38, 85, 0.9);
    /* opacity: 20%; */
  }
}

@media screen and (max-width: 1000px) {
  .navbtns {
    color: white;
  }
}

@media screen and (max-width: 1000px) {
  .ResultsBarGraph {
    height: 300px;
    max-width: 250px;

  }
}

@media screen and (min-width: 1000px) {
  .ResultsBarGraph {
    height: 500px;
    max-width: 500px;

  }
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: black;
  position: relative;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin-fill {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid yellow;
  position: absolute;
  left: -5px;
  bottom: 0px;
  transform: rotate(45deg);
}