.testContainer {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.testsmaindiv {
    margin-top: -40px;
    margin-bottom: 40px;
}

.testlisthead {
    color: black;
    margin-top: 140px;
    margin-bottom: 30px;
}

.testBox {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    color: black;
    font-weight: lighter;
    background-color: white;
    border-radius: 12px;
    box-shadow: 5px 5px 50px rgb(202, 202, 202);
    transition: 0.5s ease, transform 0.3s ease;
    font-size: 1.5rem;
    height: 200px;
    width: 300px;
    align-items: center;
    justify-content: center;
}

.testBox:hover {
    transform: scale(1.05);
    cursor: pointer;
}

