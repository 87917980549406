.resultsHeading {
    margin-top: 90px;
    color: #1C7CFC;
}

.resultsText {
    color: black;
    font-size: 1.3rem;
    max-width: 1000px;
    padding: 20px;
}

.resultsContainer {
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
}

.resultsBranches {
    padding: 15px;
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
}

.counsellingHeading {
    font-size: 30px;
    /* Adjusted font size for smaller screens */
    color: black;
    font-weight: bold;
}

.counsellingText {
    color: black;
    font-size: 20px;
    /* Adjusted font size for smaller screens */
    max-width: 1000px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
}

.bookAppointment {
    padding: 10px;
    /* Adjusted padding for smaller screens */
    border-radius: 10px;
    margin-bottom: 30px;
    /* Adjusted margin for smaller screens */
    background-color: #1C7CFC;
    color: white;
    border: 1px solid #ddd;
}

.bookingContainer {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 50px;

}

/* @media(min-width:700){
    .branchboxes{
        display: hiden;
    }
} */


/* Media Query for screens smaller than 600px */

@media (max-width: 600px) {
    .resultsHeading {
        font-size: 30px;
    }

    .resultsText {
        font-size: 18px;
        padding: 15px;
    }

    .resultsBranches {
        font-size: 16px;
        padding: 10px;
    }

    /* .chartContainer { */
    /* height: 280px; */
    /* padding-bottom: 10px;  */
    /* } */

    .counsellingHeading {
        font-size: 25px;
    }

    .counsellingText {
        font-size: 16px;
    }

    .bookAppointment {
        padding: 8px;
        margin-bottom: 20px;
    }

    .bookingContainer {
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 10px;
        margin-right: 10px;
        margin-left: 10px;
    }

}