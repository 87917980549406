.featured-post{
    margin:0.6rem 0.6rem 0.6rem 0.6rem;
    height: 270px;
    width: 260px;
    border: 2px solid rgba(178, 178, 178, 0.5);
    border-radius: 2px;
    align-self: center;
}
.recent-post{
    margin:1rem 0rem 1rem 1rem;
    height: 300px;
    width: 350px;
    background-color: rgb(224, 224, 224,0.5);
    border-radius: 4px;
}

.post-iframe{
    height: 200;
    width: 250;
}
@media only screen and (max-width: 480px) {
        .featured-post{
            width: 290px;
        }

}