.iqoptions{
    margin-top: 20px;
    padding: 10px;
    max-height: 100px;
}

.iqoptions:hover{
    transform: scale(1.2);
    border: 1.5px solid skyblue;
}

#myChart{
    max-height: 400px;
}

.selectediqoption{
    border:2px solid rgb(22, 72, 32)
}