.test-container{
    max-width: 100%;
    min-width: 60vw;
    margin-top: 100px;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-self: center;
}

@media only screen and (max-width: 768px){
   .test-container{
    max-width: 80%;
   }

   .test-main-container
   {

    justify-content: center;
   }
    
}

@media only screen and (max-width: 426px){
    .test-container{
     max-width: 85%;
    }
     
 }

 

.test-main-container{
display: flex;
}



#instruct {
    color: #1C7CFC;
    font-weight: bold;
}

#instructionTxt {
    color: black;
    text-align: center;
    font-size: 1.2rem;
}

#instructionTxt ul li {
    list-style-type: lower-alpha;
    text-align: left;
    list-style-position: inside;
    text-indent: -1.2em;
    padding-left: 1em;

}

#instructionTxt ul li::marker{

 margin-left: 10px;


}


#instructionContents {
    padding-left: 30px;
}

#loadTxt {
    color: white;
}

#questionTxt {
    font-weight: bold;
    color: black;
    font-size: 1.2rem;
}

.questionComponent {
    border: 1px solid gray;
    border-radius: 12px;
    padding: 20px 20px;
}

#optionTxt {
    color: black;
    font-size: 1.1rem;
}

#questionHeading {
    color: black;
    text-align: left;
}

.questionComponent {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

#resultBtn {
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    padding: 6px 15px;
    font-weight: 500;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

#resultBtn:hover {
    cursor: pointer;
}

.takeQuizBtns {
    display: flex;
    justify-content: center;
}

#backBtn {
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    padding: 6px 15px;
    font-weight: 500;
    margin-right: 15px;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

#backBtn:hover {
    cursor: pointer;
}

#nextBtn {
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 10px;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

#nextBtn:hover {
    cursor: pointer;
}

.loading-screen{
    margin-top: 20%;
    height: 100vh;
    font-size: 2em;
}

