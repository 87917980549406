.afterbooking-container {
  margin-top: 10px;
  padding: 20px;
  padding-bottom: 5%;
  color: black;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: 0.5s ease, transform 0.3s ease;
}

.formContents {
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

#bookingHeading {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.new-price {
  color: #e1aa74;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.price {
  color: black;
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.old-price {
  text-decoration-line: line-through;
  font-size: 15px;
  margin-top: 10px;
  margin-left: 10px;
  color: #999999;
}

.bookingSubmitBtn {
  padding: 10px;
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid #ddd;
  background-color: #1C7CFC;
  color: white;
  height: 3rem;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

:focus-visible {
  outline: none;
}

form label {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  color: #ece9c6;
}

input[type=text] {
  margin-bottom: 20px;
  background-color: white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  border-color: gray;
  min-width: 30%;
  color: black;
}

input[type=email] {
  margin-bottom: 10px;
  background-color: white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  border-color: gray;
  min-width: 30%;
  color: black;
}

input[type=tel] {
  margin-bottom: 20px;
  background-color: white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  border-color: gray;
  min-width: 30%;
  color: black;
}

@media screen and (max-width: 600px) {
  input[type=text] {
    min-width: 2px;
    max-width: 85%;
    /* Set the maximum width to 80% for smaller screens */
  }

  input[type=email] {
    min-width: 2px;
    max-width: 85%;
    /* Set the maximum width to 80% for smaller screens */
  }

  input[type=tel] {
    min-width: 2px;
    max-width: 85%;
    /* Set the maximum width to 80% for smaller screens */
  }
}

.offerSticker {
  margin-top: 10px;
}

.offer {
  border-radius: 50%;
  border: 2px solid;
  padding: 5px;
  font-size: 12px;
  border-color: #e1aa74;
  margin-top: 35px;
  margin-left: 20px;
}

.formContents {
  background-color: white;
  border-radius: 20px;
  padding: 10px;
}

#bookingNote {
  color: gray;
}