.aboutSection {
    margin-top: 5rem;
    padding: 20px;
    color: black;
    background-color: #1C7CFC;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: 0.5s ease, transform 0.3s ease;
    max-width: 70rem;
}

.abtSec {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 25px;
}

.aboutHeadings {
    color: black;
    text-align: center;
    transition: color 0.3s ease;
}

#mainAbtHead {
    color: white;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.3rem;
}

#mainAboutTxt {
    color: white;
    text-align: center;
    font-size: 1.1rem;
}

.sponsorMessage {
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.6;
    padding: 0 5% 0 5%;
}

.aboutTxt {
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.6;
    padding: 0 5% 0 5%;
}

.svgAnimation {
    margin: 2rem;
}

.highlightSection,
.sponsorSection,
.contactSection {
    background: #ffffff;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
    transition: 0.5s ease, transform 0.3s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

.highlightSection:hover,
.sponsorSection:hover,
.contactSection:hover {
    background: #f9f9f9;
    transform: scale(1.02);
}

#sponsorTxt {
    text-align: right;
}

#contactBtn {
    margin-top: 15px;
    background-color: #1C7CFC;
    color: white;
    border-radius: 12px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 10px;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

#contactBtn:hover {
    transform: scale(1.08);
}