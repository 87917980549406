/* Navbar styles */
.navbar {
    top: 0;
    /* position: sticky; */
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: var(--mainColor);
    color: var(--textColor);
    box-shadow: 0px 2px 2px 0px rgba(198, 198, 198, 0.6);
    z-index: 999;
  }
  .navbar-brand{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .navbar-brand img{
    margin-top: 5px;
  }
  #brandName{
    text-decoration: none;
    color: #157ae7;
    font-size: 25px;
    font-weight: 800;
  }
  .navbar-links{
    display: flex;
    align-items: center;
    margin-inline: 20px;
    z-index: 3;
  }
  .navbar-links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navbar-links li {
    margin-right: 20px;
  }
  
  .navbar-links a {
    color: var(--textColor);
    text-decoration: none;
  }
  
  .navbar-links a:hover {
    color: blueviolet;
  }
  
  .nav-btn {
    display: flex;
    padding: 5px;
    margin:0.45rem;
    cursor: pointer;
    background: transparent;
    border: none;
    visibility: hidden;
    outline: none;
    color: var(--textColor);
    font-size: 2rem;
    align-items: center;
  }
  
  .profile-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #333;
    min-width: 150px;
  }
  
  .profile-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 10px;
  }
  
  .profile-menu li {
    margin-bottom: 10px;
  }
  
  .profile-menu a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .profile-menu a:hover {
    color: #ffcc00;
  }
  .nav-left{
    display: none;
  }
  .responsive_nav{
    position: absolute;
    display: none;
  }
  .profile{
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-inline:10px 25px;
    border-radius: 100%;
    border: 2px solid grey;
    padding: 0px;
    background-color: lightgray;
  }

  .profilePicture{
    width:  5vh;
    height: 5vh;
    border-radius: 100%;
  }

  


  @media only screen and (max-width: 768px) {
    .navbar {
      justify-content: space-between;
    }
    #brandName{
      font-size: 23px;
    }
    #logo-b{
      display: none;
    }
    .navbar-links {
      display: flex;
      flex-direction: column;
      align-items: start;
      text-align: left;
      justify-content: end;
      background-color: white; /* Add background color */
      position: absolute;/* Position dropdown below the navbar */
      width: 100%; /* Take full width */
      transition: transform 0.3s ease-in-out; /* Add transition */
      transform: translateY(-100%); 
      align-self: center;
      padding-bottom: 15px;

    }
    
    .navbar-links ul {
      list-style-type: none;
      margin:0;
      padding:0;
      display: flex;
      flex-direction: column; /* Align items vertically */
    }
    .navbar-links li{
      padding-inline:10px;
      padding-block: 12px;
    }
    
    .navbar-links a {
      color: var(--textColor);
      text-decoration: none;
      font-size: 18px; /* Adjust font size */
    }
    
    .navbar-links a:hover {
      color: blueviolet;
    }
    
    .nav-left {
      display: block;
    }
    
    .nav-btn {
      visibility: visible;
    }

    .navbar-links.active {
      width: 100%;
      height: 80vh;
      left: 0;
      margin: 0;
      transform: translateY(0); /* Show dropdown */
      /* box-shadow: 0px 2px 2px 0px rgba(176, 176, 176, 0.6); */
    }


    .nav-close-btn{
      
      padding: 10px;
      background-color: rgb(196, 196, 196,0.5);
      border-radius: 50%;
      color: gray;
      font-weight: lighter;
      position: absolute;
      bottom: -70px;
      align-self: center;
    }
  }
  



