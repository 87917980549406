.blog {
  color: black;
}

/* test taken page */

.report-button {
  justify-content: center;
  align-items: center;
}

.report-button button {
  background-color: #31ae1e;
  border: none;
  color: white;
  padding: 5px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 10px;
  border-radius: 5px;
}

.search-bar {
  width: 100%;
  max-width: 500px;
  background-color: #ecefff;
  display: flex;
  align-items: center;
  border-radius: 60px;
  padding: 1px 2px;
}

.search-bar input {
  background-color: transparent;
  flex: 1;
  border: none;
  outline: none;
  padding: 0px 20px;
  font-size: 15px;
  color: black;
}

.search-bar .icon {
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: black;
  min-width: 15px;
}

/* blog section */

.a_blog {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.blog-card {
  width: 250px;
  height: 250px;
  background-color: rgb(233, 232, 232);
  margin: 10px;
}

.blog-input input {
  min-width: 150px;
  width: 50%;
  outline: none;
  border: none;
  background-color: #ecefff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-left: 10px;
}

.blog-input button {
  background-color: rgb(103, 128, 211);
  border: none;
  color: white;
  width: 50px;
  padding: 10px;
}

/* voucher section */

#voucheradd button {
  display: flex;
  padding: 10px;
  background-color: #31ae1e;
  border: none;
  color: white;
  font-size: medium;
  cursor: pointer;
}

#voucheradd button:hover {
  display: flex;
  padding: 10px;
  background-color: #108c20;
  border: none;
}

#voucher .action {
  padding: 10px;
  background-color: rgb(254, 157, 157);
}
