body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  font-family: "Montserrat", sans-serif;
}

/* Icons stylings */
.a_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.a_icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.a_icon,
.a_icon_header {
  margin-right: 5px;
}

.a_close_icon {
  color: rgb(255, 255, 255);
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.a_grid-container {
  display: grid;
  grid-template-columns: 223px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "sidebar header header header"
    "sidebar main main main";
  height: 100vh;
}

/* ******************* */

/*1. Header section of dashboard*/
.a_header {
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
}

.a_menu-icon {
  display: none;
}
/* ******************* */

/*2. Sidebar section of dashboard */

#sidebar {
  grid-area: sidebar;
  height: 100%;

  background: linear-gradient(
    90deg,
    rgba(7, 12, 75, 0.9613970588235294) 0%,
    rgba(15, 30, 112, 1) 100%,
    rgba(24, 55, 163, 1) 100%
  );
  overflow-y: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.a_sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 0px 30px;
  margin-bottom: 30px;
}

.a_sidebar-title > span {
  display: none;
}

.a_sidebar-brand {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.a_sidebar-list {
  padding: 0;
  list-style-type: none;
}

.a_sidebar-list-item {
  padding: 10px 20px 10px 20px;
  font-size: 18px;
}

.a_sidebar-list-item:hover {
  background-color: rgb(247, 247, 247);
  border-radius: 20px 0px 0px 20px;
  cursor: pointer;
}
.a_sidebar-list-item:hover > a {
  color: #050505;
}

.a_sidebar-list-item > a {
  text-decoration: none;
  color: #f6f3f3;
}

.a_sidebar-responsive {
  display: inline !important;
  position: absolute;
  z-index: 12 !important;
}
.close-sidebar{
  z-index: 12 !important;
}

.a_icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.a_icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.a_icon,
.a_icon_header {
  margin-right: 5px;
}

.a_close_icon {
  color: rgb(251, 251, 251);
  margin-left: 30px;
  margin-top: 10px;
  cursor: pointer;
}

/* ******************* */

/*3. Dashboard section*/
.a_main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
}

/* .a_logo{
  display:relative;
  bottom: 40px;
} */
.a_main-title {
  display: flex;
  justify-content: space-between;
  color: black;
}
.text-color{
  color: rgb(98, 98, 98);
}

.a_main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.a_card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 15px;
  border-radius: 5px;
  background: linear-gradient(
    90deg,
    rgba(7, 12, 75, 0.9613970588235294) 0%,
    rgba(15, 30, 112, 1) 100%,
    rgba(24, 55, 163, 1) 100%
  );
  /* box-shadow: 1px 10px gray; */
}

.a_card-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a_card-inner > .card_icon {
  font-size: 25px;
}

/* table */
.a_table {
  margin-top: 20px;
  overflow: auto;
}

#users {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#users td,
#users th {
  border: 1px solid #ddd;
  padding: 8px;
}

#users td {
  color: black;
}

#users th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background: linear-gradient(
    90deg,
    rgba(7, 12, 75, 0.9613970588235294) 0%,
    rgba(15, 30, 112, 1) 100%,
    rgba(24, 55, 163, 1) 100%
  );
  color: white;
}

#users tr:nth-child(even) {
  background-color: #ecefff;
}

#users tr:nth-child(odd) {
  background-color: #fefefe;
}

/* ******************* */

/*   media queries   */

@media screen and (max-width: 992px) {
  .a_grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }

  #sidebar {
    display: none;
  }

  .a_menu-icon {
    display: inline;
  }

  .a_sidebar-title > span {
    display: inline;
  }
}

@media screen and (max-width: 768px) {
  .a_main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 576px) {
  .a_header-left {
    display: none;
  }
}

/* *********The End********** */
