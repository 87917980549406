.signup-container{
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 35px;
    /* left: 20%;*/
    width: 65vh;
    height: 80vh;
    background-color: white;
    border-radius: 4px;
    box-shadow: 3px 2px 9px 0px rgba(0,0,0,0.5);
    z-index: 1;
}


.login-with-google{
    align-self: center;
}
@media only screen and (max-width: 480px) {
    .signup-container{
        height: 64vh;
    }
   
}