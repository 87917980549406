.auth-main-container {
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
}
.auth-main-container
{
    background-color: rgb(240, 240, 240);
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#card-margin{
    height:55vh;
    width: 10px;
    background-color: rgba(35, 55, 232, 0.9);
}
.auth-container {
    padding-left: 2%;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    height: 55vh;
    /* Old background-color */
    /* background-color: rgb(57, 122, 225,0.6); */

    /* New background with linear gradient */
    background: linear-gradient(to left, rgba(101, 148, 224, 0.6), rgba(23, 87, 198, 0.7)); 
}


.toggle-login-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.toggle-signup-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}
.toggle-signup-container *{
    padding-block: 5px;
    color: white;
}
.toggle-login-container *{
    padding-block: 5px;
    color: white;
}
.auth-btn{
    margin-top: 10px;
    width: fit-content;
    padding: 8px 4rem 8px;
    border-style: none;
    color: white;
    background-color: inherit;
    border:1px solid white;
}
.auth-btn:hover{
    color: inherit;
    background-color: white;
    border:1px solid inherit;
}
/* header{
    display: none;
} */


@media only screen and (max-width: 480px) {
    .toggle-login-container,
    .toggle-signup-container,
    #card-margin{
        display: none;
    }
    .auth-container
    {
        height: 40vh;
    }
}

@media only screen and (max-width: 780px) {
    .toggle-login-container,
    .toggle-signup-container
    {
        padding-inline: 10px;
        
    }
}