

.login-container{
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 35px;
    width: 65vh;
    height: 70vh;
    background-color: white;
    border-radius: 4px;
    box-shadow: 3px 2px 9px 0px rgba(0,0,0,0.5);
    z-index: 1;
}
#logo-image{
    filter: brightness(0) invert(1);
}
#logo-contaniner{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding-right: 5px;
    width: fit-content;
    background-color: #1c7dfca0;   
    /* border: 1px solid black; */
}
#logo-title{
    display: flex;
    flex-direction: column;
}
#logo-contaniner p{
    margin-top: 10px;
    font-size: 22px;
    color: white;
    font-weight: 900;
}
.form-content{
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.form-content-login{
    margin-bottom: 5%;
    text-align: left;
}

.form-content-login > span{
    font-size: larger;
    font-weight: bolder;
}
.form-content-login  > p {

    font-size: x-small;
    opacity: 0.5;
}
.login-form{
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.form-content{
    padding-inline: 10%;
    display: flex;
    flex-direction: column;
    justify-items: center;
   
}

#email-lable{
    color: grey;
    font-size: x-small;
    font-weight: bolder;
    text-align: left;
}
.btn-main{
    background:linear-gradient(to right, rgba(101, 148, 224, 0.6), rgba(23, 87, 198, 0.7)); 
    border-style: none;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
    cursor: pointer;

    border-radius: 3px;
}

.inputs input{
    padding-top: 10px;
    margin: 0;
    border: none;
}
.inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid lightgray;
    margin-bottom: 10px;
}
.inputs:focus-within{
    border-bottom: 2px solid black;
}

.checks{
    display: flex;  
    justify-content: space-between;
    
    align-items: center;
   margin-top: 2%;
}


#remember,#showpass{
    text-align: left;
    margin-bottom: 20px;


}

#remember label,#showpass label{
    vertical-align: 6px;
    padding-inline: 4px;
    font-size: x-small;
    color: grey;
}
.login-with-google {
    margin-top: 10px;
    background:linear-gradient(to right, rgba(101, 148, 224, 0.6), rgba(23, 87, 198, 0.7)); 
 
    min-width: 200px;
    height: 40px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-style: none;
    border-radius: 3px;
    padding: 3px;
}
.image-holder {
    background-color: white;
    width: 10%;
     padding: 3%;
    border-radius: 3px;
}
.login-with-google-text {
    width: 100%;
    margin-left: 3px;
    font-size: 0.8rem;
}

@media only screen and (max-width: 480px) {
    .login-container{
        height: 55vh;
    }
    .btn-main{
        visibility: visible;
    }
}