.test-side-bar{
    width: 25%;
    background-color: #1C7CFC;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    min-width: 20em;

    
}

.test-side-bar-box{
    background-color: white;
    margin: 10%;
    margin-top: 30%;
    width: 80%;
    height: 30em;

    position: static;
    display: flex;
    flex-direction: column;

    justify-items: center;

}


.profile-section{
    margin-top: 5%;
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.profile-picture{
    border-radius: 100%;
    width: 20%;
    margin-top: 5%;
}

.profile-name{
 font-size: large;
 margin-top: 2%;
}

.profile-email{
font-size: 0.75rem;
color: gray;
}

.current-test-image{
    width: 30%;
    margin-top: 10%;
    justify-self: center;
    align-self: center;
}

.in-progress-text{
    margin-top: 10%;
    font-size: small;
}

.current-test-name{
    font-weight: bold ;
    font-size: large;
}

.progress-section{
    display: none;
}

@media only screen and (max-width: 768px){
    .test-side-bar{
     display: none;
    }
     
 }