

section{
    width: 100%;
    background-color: #ffff;
}

.landing-page{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
}


.landing-content1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0;
    height: 550px;
    width: 100%;
    background:linear-gradient(to top,#96a2c2,#0b3199) ;
    margin: 1rem 1rem 0rem;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}


#blog-heading{
    font-weight: bolder;
    width: fit-content;
    color: white;
    padding: 20px;
    font-size: 50px;
}


#blog-description{
    font-size: large;
    width: 50%;
    color: rgb(255, 255, 255);
}

.landing-subcontainer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.blog-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newsletter{
    color: rgb(140, 140, 140);
    background-color: #000000;
    height: 200px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}





#right-image{
    display: flex;
    justify-content: center;
}


.landing-content-sopnsers{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.blog-navigation{
    position: sticky;
    top: 4rem;
    margin-top: 4rem;
    height: 40px;
    background-color: #3b6fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 1;
}

.tag{
    font-size: small;
    border-radius: 1rem;
    border: 1px solid white;
    padding: 2px 10px;
    color: white;
}
.tag:hover {
    cursor: pointer;
    background-color: white;
    color: #3b6fff;
}



/* #tag-container{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 5px;
    width: 60%;
    height: fit-content;
    padding: 1rem;
    margin: 1.5rem;
    border-radius: 1rem;
    background-color: rgb(223, 223, 223);
} */
#show-more{
    background-color: black;
    color: white;
}

.post-content{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.PostCard-container{
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto ;
    justify-content: center;
}

.left-heading{
    top: 6.5rem;
    position: sticky;
    background-color:#3f6ff3;
    z-index: 1;

}
.left-heading  p{
    color: white;
}












/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
.demo {
    background: radial-gradient(100% 225% at 100% 0%, #ff0202 0%, #c02626 100%), linear-gradient(236deg, #00C2FF 0%, #000000 100%), linear-gradient(135deg, #CDFFEB 0%, #CDFFEB 36%, #009F9D 36%, #009F9D 60%, #07456F 60%, #07456F 67%, #0F0A3C 67%, #0F0A3C 100%);
    background-blend-mode: overlay, hard-light, normal;
}

.subscribe {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* .subscribe  div input:focus {
    border-color: #ea0808;
    outline: none;
    animation: borderAnimation3 2s infinite;
} */

@keyframes borderAnimation3 {
    0% {
        border-color: #ea0808;
    }

    33% {
        border-color: #083aea;
    }

    66% {
        border-color: #08ea08;
    }

    100% {
        border-color: #ea0808;
    }
}


/* .form {
    display: flex;
    flex-direction: column;
    background: linear-gradient(125deg, #00FF57 0%, #010033 40%, #460043 70%, #F0FFC5 100%), linear-gradient(55deg, #0014C9 0%, #410060 100%), linear-gradient(300deg, #FFC700 0%, #001AFF 100%), radial-gradient(135% 215% at 115% 40%, #393939 0%, #393939 40%, #849561 calc(40% + 1px), #849561 60%, #EED690 calc(60% + 1px), #EED690 80%, #ECEFD8 calc(80% + 1px), #ECEFD8 100%), linear-gradient(125deg, #282D4F 0%, #282D4F 40%, #23103A calc(40% + 1px), #23103A 70%, #A0204C calc(70% + 1px), #A0204C 88%, #FF6C00 calc(88% + 1px), #FF6C00 100%);
    background-blend-mode: overlay, screen, overlay, overlay, normal;
    padding: 20px;
    border-radius: 10px;
    max-width: 350px;
    width: 100%;
} */

.title {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    letter-spacing: -0.025em;
    color: #fff;
  }
  
  .description {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-top: 1rem;
    color: rgb(209 213 219);
  }
  

.description a {
    color: #ffffff;
}

.form div {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 28rem;
    margin-top: 1rem;
    column-gap: 0.5rem;
}

.form div input {
    outline: none;
    line-height: 1.8rem;
    font-size: 0.875rem;
    color: rgb(255 255 255 );
    padding: 0.5rem 0.875rem;
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgba(253, 253, 253, 0.363);
    border-radius: 0.375rem;
    flex: 1 1 auto;
  }
  
  .form div input::placeholder {
    color: rgb(216, 212, 212);
  }
  
  .form div input:focus {
    border: 1px solid rgb(22, 25, 230);
  }

@keyframes borderAnimation {
    0% {
        border-color: #d008ea;
    }

    33% {
        border-color: #f10303;
    }

    66% {
        border-color: #088004;
    }

    100% {
        border-color: #eadb08;
    }
}

.form div input:focus+.underline {
    transform: scaleX(1);
}


.form div button {
    color: #030000;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-inline:0.5rem;
    padding-block: 0.8rem;
    color: white;
    background-color: #113ddc;
    /* background: linear-gradient(125deg, #FFFFFF 0%, #000000 100%), linear-gradient(200deg, #FFD9E8 0%, #FFD9E8 50%, #DE95BA calc(50% + 1px), #DE95BA 60%, #7F4A88 calc(60% + 1px), #7F4A88 75%, #4A266A calc(75% + 1px), #4A266A 100%), linear-gradient(113deg, #FFD9E8 0%, #FFD9E8 40%, #DE95BA calc(40% + 1px), #DE95BA 50%, #7F4A88 calc(50% + 1px), #7F4A88 70%, #4A266A calc(70% + 1px), #4A266A 100%); */
    background-blend-mode: overlay, overlay, normal;
    border-radius: 0.375rem;
    border: none;
    outline: none;
    margin-bottom: 0.6rem;
}








/* @media queries */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
@media only screen and (max-width: 480px) {
    .PostCard-container{
        grid-template-columns: auto;
    }
    .left-heading, .blog-navigation {
         z-index: 1;
      }
      .landing-content-sopnsers{
            flex-direction: column;
      }
      #blog-heading{
        font-size: 30px;
      }
      #blog-description{
        font-size: small;
        width: 100%;
    }
    .landing-content1{
        width: 80%;
    }
    .subscribe{
        width: 80%;
    }

}