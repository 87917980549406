#contactHeading {
    color: black;
}

.contactForm input {
    margin-bottom: 20px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
    border-color: gray;
    min-width: 30%;
    max-width: 800px;
    color: black;
}

.contactForm input[type=email] {
    margin-bottom: 20px;
    background-color: white;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
    border-color: gray;
    max-width: 1000px;
    color: black;
}

.contactForm input[type=text] {
    margin-bottom: 20px;
    background-color: white;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
    border-color: gray;
    max-width: 1000px;
    color: black;
}

.contactForm input[type=number] {
    margin-bottom: 20px;
    background-color: white;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
    border-color: gray;
    max-width: 1000px;
    color: black;
}

.contactForm input[type=tel] {
    margin-bottom: 20px;
    background-color: white;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
    border-color: gray;
    max-width: 1000px;
    color: black;
}

#contactSubmitBtn {
    background-color: #1C7CFC;
    color: white;
    margin-top: 15px;
    border-radius: 12px;
    padding: 6px 15px;
    font-weight: 500;
    margin-left: 10px;
    font-size: 1.1rem;
    border: 1px solid #ddd;
}

