#preTestHeading {
  color: black;
}

.preTestForm input[type=email] {
  margin-bottom: 20px;
  background-color: white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  border-color: gray;
  margin-left: 10px;
  max-width: 800px;
  color: black;
}

.preTestForm input:focus-visible {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px#23232329;
}

.preTestForm input[type=text] {
  margin-bottom: 20px;
  background-color: white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 2px;
  border-color: gray;
  margin-left: 10px;
  max-width: 800px;
  color: black;
}

.disclaimer {
  margin: auto;
  height: 10vh;
  width: 80%;
  overflow-y: scroll;
  max-width: 400px;
}

/*  disclaimer slide-bar  */
.disclaimer::-webkit-scrollbar {
  width: 5px;
}

.disclaimer::-webkit-scrollbar-thumb {
  background-color: none;
  border-radius: 10px;
}

.disclaimer::-webkit-scrollbar-track {
  background-color: #1C7CFC;
}

#disclaimerTxt {
  color: gray;
  font-size: 0.8rem;
  text-align: left;
}

input[type='checkbox'] {
  height: 2.5vh;
  width: 2.5vw;
  min-width: 15px;
}

#preTestSubBtn {
  margin-top: 15px;
  background-color: #1C7CFC;
  color: white;
  border-radius: 12px;
  padding: 6px 15px;
  font-weight: 500;
  margin-left: 10px;
  font-size: 1.1rem;
  border: 1px solid #ddd;
}

#preTestSubBtn:hover {
  cursor: pointer;
}

#confirmTxt {
  color: black;
}