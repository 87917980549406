.sponsorsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  margin-bottom: 20px;
}

#sponsorsTxt {
  margin-bottom: 5px;
  color: black;
  font-size: 1.2rem;
  width: 100%;
}

.sponsors-logo {
  cursor: pointer;

  justify-content: center;
  align-content: center;
  margin: 0vh 4vh;
}

#sahyadri-sponsor-logo{
  height: 45px;
  align-self: center;
}

#panama-sponsor-logo{
  height: 70px;
  align-self: center; 
}

#surepass-sponsor-logo{
  height: 60px; 
  align-self: center; 
}

#torsecure-sponsor-logo{
 height: 60px;
 align-self: center;
}



.sponsors-image-band{
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
/*
.sponsorsContainer img {
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
  min-width: 150px;

}
*/
.sponsorItem {
  margin: 10px;
  flex: 1 0 100%;
}


@media (max-width: 1024px) {
  #sahyadri-sponsor-logo{
    height: 35px;
    align-self: center;
  }
  
  #panama-sponsor-logo{
    height: 50px;
    align-self: center; 
  }
  
  #surepass-sponsor-logo{
    height: 50px; 
    align-self: center; 
  }
  
  #torsecure-sponsor-logo{
   height: 50px;
   align-self: center;
  }

  .sponsors-logo {
    cursor: pointer;
    justify-content: center;
    align-content: center;
    margin: 2vh 4vh 
  }
  
  .sponsors-image-band{
    display: flex;;
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  #sahyadri-sponsor-logo{
    height: 30px;
    align-self: center;
  }
  
  #panama-sponsor-logo{
    height: 50px;
    align-self: center; 
  }
  
  #surepass-sponsor-logo{
    height: 40px; 
    align-self: center; 
  }
  
  #torsecure-sponsor-logo{
   height: 40px;
   align-self: center;
  }

  .sponsors-logo {
    cursor: pointer;
    justify-content: center;
    align-content: center;
    margin: 2vh 2vh
  }
  

}

@media (max-width: 480px) {
  #sahyadri-sponsor-logo{
    height: 40px;
    align-self: center;
  }
  
  #panama-sponsor-logo{
    height: 70px;
    align-self: center; 
  }
  
  #surepass-sponsor-logo{
    height: 60px; 
    align-self: center; 
  }
  
  #torsecure-sponsor-logo{
   height: 70px;
   align-self: center;
  }

  .sponsors-logo {
    margin: 2vh 0vh
  }
   .sponsors-image-band{
    flex-direction: column;
   }
  
}


@media (max-width: 320px) {
  #sahyadri-sponsor-logo{
    height: 30px;
    align-self: center;
  }
  
  #panama-sponsor-logo{
    height: 50px;
    align-self: center; 
  }
  
  #surepass-sponsor-logo{
    height: 50px; 
    align-self: center; 
  }
  
  #torsecure-sponsor-logo{
   height: 50px;
   align-self: center;
  }

  .sponsors-logo {
    margin: 2vh 0vh
  }
   .sponsors-image-band{
    flex-direction: column;
   }
  
}